/**
 * Link element
 */

a {
  color: var(--color-brand, #{$color-blue-500});
  text-decoration-skip-ink: auto;

  &:hover,
  &:focus {
    text-decoration: none;
    color: $color-blue-700;
  }

  &:focus {
    @include generic-focus;
  }

  .whitelabel-theme & {
    &:hover,
    &:focus {
      color: var(--color-brand, #{$color-blue-500});
      filter: brightness(1.25);
    }
  }
}

.c-link {
  display: inline-block;
  position: relative;
}

.c-link--fat {
  font-weight: 800;
}

.c-link--chevrons {
  padding-right: 1.25ch;

  &::after {
    position: absolute;
    right: 0;
    content: '»';
    text-decoration: none;
  }
}

.c-link--invert-hover {
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
