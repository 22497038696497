/**
 * Combined form component
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.c-combined-form {
  margin-top: $supple-space-base;

  @include mq($from: md) {
    margin-top: $supple-space-large;
  }

  @include mq($from: lg) {
    width: percentage(8/12);
    padding-left: percentage(1/12);
    margin-top: $supple-space-large + $supple-space-small;
  }
}

/**
 * ======================================
 * Elements
 * ======================================
 */
.c-combined-form__form {
  border-radius: $border-radius-base;
  background-color: $color-gray-100;
  padding-top: 30px - $supple-space-base;
  padding-bottom: 30px - $supple-space-base;
  min-height: 250px;

  // Specific disabled styling here because of the gray background
  .c-input-text:disabled {
    background-color: $color-white;
    opacity: 0.6;
  }
}

.c-combined-form__form-item {
  padding: $supple-space-small;

  &:not(:last-child) {
    border-bottom: 1px solid $color-gray-200;
  }

  @include mq($from: sm) {
    padding: $supple-space-base;
  }

  @include mq($from: md) {
    padding: $supple-space-base 40px;
  }
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */
.c-combined-form__form-item--no-border {
  &:not(:last-child) {
    border-bottom: none;
  }
}
