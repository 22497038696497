/**
 * ======================================
 * Block
 * ======================================
 */
:root {
  --text-color: #{$color-gray-900};
  --background-color: transparent;
  --border-color: #{$color-gray-200};
}

.c-card {
  color: var(--text-color);
  border-radius: $border-radius-base;
  background-color: var(--background-color);
  padding: $supple-space-base;
  border: 1px solid;
  border-color: var(--border-color);
  position: relative;
  overflow: hidden;
  font-size: clamp(0.875rem, 4vw, 1rem);
  transition: border $base-animation;
}

/**
 * ======================================
 * Elements
 * ======================================
 */

.c-card__title-views {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;

  @include mq($until: lg) {
    flex-wrap: wrap;
  }
}

.c-card__title {
  font-size: (20em/16);
  font-weight: bold;
  margin: 0;
  padding-right: $supple-space-base;
  flex: 1 1 auto;
}

.c-card__link {
  color: inherit;
}

.c-card__views {
  flex: 0 0 auto;
  display: inline-flex;
  align-items: center;
  color: $color-gray-300;
}

.c-card__views-icon {
  width: 1em;
  height: 1em;
  margin-right: 1ch;
  color: $color-green-500;
}

.c-card__description {
  position: relative;
  color: $color-gray-500;
  max-width: 70ch;
  font-size: 1.125em;
  overflow: hidden;
  height: 3em; // 1.5 line-height * 2
  margin: $supple-space-tiny 0 $supple-space-small;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    height: 1.5em;
    width: 10ch;
    max-width: 50%;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgb(255, 255, 255) 80%
    );
  }
}

.c-card__properties-cta-container {
  margin-top: 1.75em;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;

  .c-card__image-container + & {
    margin-top: $supple-space-tiny;

    .c-properties,
    .c-card__note {
      margin-top: $supple-space-tiny;
    }

    .c-card__note {
      transform: translateY(-0.4em);
    }
  }
}

.c-card__cta {
  margin-bottom: calc((var(--target-small-height) - 1.5em) / -2);
  margin-left: auto;

  .c-button__icon {
    max-width: 1ex;
    max-height: 1ex;
  }
}

.c-card__image-container {
  $container-width: 150px;
  margin-top: $supple-space-small;
  width: $container-width;
  height: $container-width * 0.5625;
  position: relative;
  transition: background-color $base-animation-duration;

  &:empty {
    background: $color-gray-100;
  }

  img {
    object-position: left;
  }
}

.c-card__note {
  font-size: $font-size-small;
  margin-top: $supple-space-base;
}

.c-card__cv-title {
  display: flex;
  gap: $supple-space-tiny;
  align-items: center;

  span {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */
.c-card--white-background {
  background: $color-white;
}

.c-card--has-link {
  &:hover,
  &:focus-within {
    background: $color-blue-50;

    .c-card__link,
    .c-card__cta {
      color: $color-blue-700;
      text-decoration: none;
    }

    .c-card__description::after {
      background: linear-gradient(
        to right,
        rgba($color-blue-50, 0),
        $color-blue-50 80%
      );
    }
  }
}

.c-card--blue {
  background: $color-blue-500;
  color: $color-white;
  border: none;
}

.c-card--error {
  --text-color: #{$color-red-900};
  --background-color: #{$color-red-100};
  --border-color: #{$color-red-500};

  &:not(:last-of-type) {
    margin-bottom: $supple-space-small;
  }
}

.c-card--success {
  --text-color: #{$color-green-900};
  --background-color: #{$color-green-100};
  --border-color: #{$color-green-500};
}

.c-card--warning {
  --background-color: #{$color-yellow-100};
  --border-color: #{$color-yellow-500};
}

.c-card--info {
  --text-color: #{$color-blue-900};
  --background-color: #{$color-blue-50};
  --border-color: var(--color-brand, #{$color-blue-500});

  .whitelabel-theme & {
    --background-color: #{$color-gray-50};
    --border-color: #{$color-gray-900};
  }
}

.c-card--note-disabled {
  .c-card__note {
    color: $color-gray-500;
    text-decoration: line-through;
  }
}

.c-card--layout-flashmessage {
  width: 100%;

  .c-card {
    border-radius: 0;
    margin-bottom: 0 !important;

    p {
      max-width: 80rem;
      padding: 0 $supple-space-base;
      margin: 0 auto !important;
    }
  }
}

.c-card--active-job-posting {
  @include mq($from: xl) {
    .c-job-posting-list__split-container & {
      border-color: $color-blue-500;
    }
  }
}
