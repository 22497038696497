/*  =========================================================================
    elements.rule
    ========================================================================= */

/*  Variables
    ========================================================================= */

/*  Functions/Mixins
    ========================================================================= */

/*  Module
    ========================================================================= */

hr {
  border: none;
  border-bottom: 1px solid $color-gray-300;
}
