/**
 * related-company-branches
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.c-related-company-branches {
  // Empty for reference
}

/**
 * ======================================
 * Elements
 * ======================================
 */
.c-related-company-branches__list-item {
  position: relative;

  &:not(:last-child) {
    margin-bottom: $supple-space-small;
  }
}

.c-related-company-branches__count {
  position: relative;
  display: block;
  padding-left: $supple-space-base;
  color: $color-gray-700;

  &::before {
    position: absolute;
    left: 0;
    width: 1em;
    height: 1em;
    content: '';
    top: 0.65em; // line-height / 2
    transform: translateY(-50%);
    background: url(/static/svg/list-bullet.svg) no-repeat center;
    background-size: contain;
  }
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */
