/**
 * ======================================
 * Block
 * ======================================
 */

.c-employer-page {
  //
}

/**
 * ======================================
 * Elements
 * ======================================
 */

.c-employer-page__address {
  margin: $supple-space-tiny 0;
  color: $color-gray-300;
}

.c-employer-page__summary {
  font-size: 0.875em;
  display: grid;
  grid-template: auto / auto 1fr;
  gap: 0 1em;

  dt,
  dd {
    margin: 0;
  }

  @include mq($from: md) {
    grid-template: auto / auto 1fr auto 1fr;
  }
}
