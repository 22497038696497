/**
 * Dashboard-links
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.c-dashboard-links {
  // Empty for reference
}

/**
 * ======================================
 * Elements
 * ======================================
 */
.c-dashboard-link__list-item {
  padding: 0.125em 0;

  @media (pointer: fine) {
    padding: 0.25em 0;
  }

  &:not(:last-child) {
    border-bottom: 1px dotted $color-gray-300;
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
}

.c-dashboard-link__link {
  justify-content: flex-start;
  text-decoration: none;

  .c-button__icon-container {
    width: calc(1ch + 1.5em);
  }

  &.current {
    color: $color-gray-900;

    .c-button__icon {
      color: $color-green-500;
    }
  }
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */
.c-dashboard-links--header-menu {
  @include mq($until: lg) {
    .c-dashboard-link__list-item {
      padding: 0;
    }

    .c-button__icon-container {
      display: none;
    }
  }
}
