/**
 * Features-list
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.c-features-list {
  list-style-type: none;
  padding: 0;
  margin-left: 0;
  max-width: 70ch;
}

/**
 * ======================================
 * Elements
 * ======================================
 */
.c-features-list__item {
  position: relative;
  padding-left: 2em;
  white-space: pre-line; // Keep line breaks

  &:not(:last-child) {
    margin-bottom: 0.25em;
  }

  &::before {
    position: absolute;
    left: 0;
    width: 1em;
    height: 1em;
    content: '';
    top: 0.75em; // line-height / 2
    transform: translateY(-50%);
    background: url(/static/svg/list-bullet.svg) no-repeat center;
    background-size: contain;
  }
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */
