/**
 * Company logo
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.c-company-logo {
  width: 280px;

  > span {
    position: unset !important;
  }
}

/**
 * ======================================
 * Elements
 * ======================================
 */
.c-company-logo__image {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */
