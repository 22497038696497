/**
 * ======================================
 * Block
 * ======================================
 */

.c-bottom-nav {
  background: $color-gray-100;
  border-radius: $border-radius-base;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: calc(#{$supple-space-base} - 0.25em) $supple-space-base;

  @media (min-width: $sidebar-width  * 3) {
    grid-area: 2/1/3/-1;
    margin: 0 -1.5rem;
  }
}

/**
 * ======================================
 * Elements
 * ======================================
 */

.c-bottom-nav__link {
  flex: 0 0 auto;

  @media (max-width: ($sidebar-width  * 3) - 1) {
    &:last-child {
      display: none;
    }
  }
}
