/**
 * Control input(checkbox/radio)
 */
$c-control-width: 1.125em !default;
$c-control-space: $c-control-width * 1.5 !default;
$c-control-line-height: $c-control-width !default;
$c-control-border-color: $color-blue-500 !default;
$toggle-padding: 2px;

/**
 * ======================================
 * Block
 * ======================================
 */
.c-control {
  position: relative;
  line-height: $c-control-line-height;
  cursor: pointer;

  .c-control__input {
    // empty for reference
    &:focus + * {
      border-color: $color-gray-500;
    }
  }

  &:focus-within {
    .c-control__input {
      &:focus + * {
        border-color: $color-blue-500;
      }
    }
  }
}

/**
 * ======================================
 * Elements
 * ======================================
 */
.c-control__input {
  transform: translateX(
    calc(#{$c-control-width} / 2 + #{$supple-space-tiny} + #{$toggle-padding})
  );
}

.c-control__indicator {
  display: block;
  position: relative;
  @include supple-rem(width, $c-control-width);
  @include supple-rem(height, $c-control-width);
  @include supple-rem(line-height, $c-control-width);
  border: $border-base;
  border-radius: 5px;
  text-align: center;
  background-color: $color-white;
  user-select: none;
  box-sizing: content-box;
  transition: border-color $base-animation-duration;
  color: var(--color-brand-secondary, #{$color-green-500});
  padding: 0 !important;
  margin-left: $supple-space-tiny;

  .c-control:hover &,
  .c-control:focus & {
    border-color: $color-blue-500;
  }

  .c-control__input:checked ~ & {
    svg {
      stroke-dashoffset: 0;
      opacity: 1;
    }

    &::after {
      transition: all $base-animation-duration;
      background-color: $color-green-500;
      transform: translate(-50%, -50%) scale(1);
    }
  }

  .c-control__input:disabled ~ & {
    border-color: $color-gray-500;
    color: $color-gray-500;
    background-color: $color-gray-100;
    cursor: not-allowed;

    &::after {
      background-color: $color-gray-500;
      transform: translate(-50%, -50%) scale(1);
    }
  }
}

.whitelabel-theme {
  .c-control:hover .c-control__indicator,
  .c-control:focus .c-control__indicator {
    border-color: $color-gray-900;
  }
}

.c-control__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.c-control__label {
  font-weight: normal;

  .c-control__input:checked ~ & {
    color: $color-black;
  }
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */
.c-control--checkbox {
  .c-control__indicator > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: $font-size-small;
    height: $font-size-small/12 * 9;
    transform: translate(-50%, -50%);
    opacity: 0;
    stroke-dashoffset: 1;
    transition: stroke-dashoffset $base-animation-duration / 2;
  }
}

.c-control--radio {
  .c-control__indicator {
    border-radius: 50%;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 64%;
      height: 64%;
      transform: translate(-50%, -50%) scale(0);
      background-color: $color-white;
      border-radius: 50%;
      transition: transform $base-animation-duration;
    }
  }
}
