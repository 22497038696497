/**
 * Properties
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.c-properties {
  margin: 0;
  margin-left: -$supple-space-base;
  padding-right: $supple-space-base;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

/**
 * ======================================
 * Elements
 * ======================================
 */
.c-properties__property {
  position: relative;
  padding-left: $supple-space-base;
  display: grid;
  gap: 1ch;
  grid-template: auto / 1em 1fr;
  flex: 0 0 auto;
  max-width: 100%;
  margin-bottom: 0.5ch;
}

.c-properties__property-icon-container {
  display: flex;
  align-items: center;
  place-self: start;
  width: 100%;
  transform: translateY(0.75em) translateY(-50%);
}

.c-properties__property-icon {
  width: 100%;
  color: var(--color-brand-secondary, #{$color-green-500});
}

.c-properties__property-value {
  margin: 0;
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */
.c-properties__property--spacing-small {
  margin-left: -$supple-space-small;
  padding-right: $supple-space-small;
  .c-properties__property {
    padding-left: $supple-space-small;
  }
}

.c-properties__property--full-width {
  flex: 1 1 100%;
}

.c-properties__property--no-icon {
  display: block;
}
