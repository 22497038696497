/*  =========================================================================
    settings.vars
    Global variables, config switches.
    Used in conjunction with supple css.
    ========================================================================= */

/*  =========================================================================
    Overrides
    Any variables you find set in supple that you do not wish to keep, simply
    redefine here. This means that if supple sets your`$supple-font-size`
    at 16px and you wish it to be 18px, simplyredeclare that variable
    in this section.
    ========================================================================= */

/*  Typography
    ========================================================================= */

$supple-font-size: 16px;
$supple-line-height: 24px;

/*  =========================================================================
    Custom
    Place any of your own variables that sit on top of supple here.
    Please use the `!default` flag because variables defined here can be
    overwritten by another theme
    ========================================================================= */

/*  Typography
    Base typographic settings
    ========================================================================= */

$font-family-base: var(--font-family-base) !default;
$font-family-heading: var(--font-family-heading) !default;

$font-size-large: 1.125rem !default;
$font-size-base: 1rem !default;
$font-size-small: 0.875rem !default;
$font-size-h1: 2.125rem;
$line-height-h1: 1.05;
$font-size-h2: 1.75rem;
$line-height-h2: 0.9375;
$font-size-h3: 1.25rem;
$line-height-h3: 1.04;
$font-size-h4: 1.125rem;
$line-height-h4: 1;

/*  Colors
    No really discernable primary or secondary color,
    so let's use some sensible names
    ========================================================================= */

$color-blue-50: #eff3ff;
$color-blue-100: #c9d9ff;
$color-blue-200: #c0d6ff;
$color-blue-500: #4444f1;
$color-blue-500-transparent: #4444f11c;
$color-blue-700: #2600a1;
$color-blue-900: hsl(214, 100%, 7%); // OLD color-blue-900

$color-green-100: hsl(143, 50%, 90%);
$color-green-200: hsl(143, 50%, 53%);
$color-green-500: hsl(143, 100%, 33%);
$color-green-700: hsl(143, 100%, 23%);
$color-green-900: hsl(143, 100%, 7%);

$color-yellow-100: hsl(45, 87%, 90%);
$color-yellow-200: hsl(45, 87%, 82%);
$color-yellow-500: #fce400;
$color-yellow-700: hsl(45, 87%, 42%);

$color-red-100: hsl(0, 100%, 95%);
$color-red-200: hsl(0, 100%, 77%);
$color-red-500: hsl(0, 100%, 57%);
$color-red-700: hsl(0, 100%, 37%);
$color-red-900: hsl(0, 100%, 17%);

$color-purple-200: hsl(334, 78%, 62%);
$color-purple-500: hsl(334, 78%, 42%);
$color-purple-700: hsl(334, 78%, 22%);

$color-white: hsl(0, 0%, 100%);

$color-gray-50: hsl(0, 0%, 97%); // #F7F7F7
$color-gray-100: hsl(0, 0%, 92%); // #EBEBEB
$color-gray-200: hsl(0, 0%, 86%); // #DBDBDB
$color-gray-300: hsl(0, 0%, 73%); // #BABABA
$color-gray-500: hsl(240, 1%, 59%); // #959597
$color-gray-700: hsl(240, 1%, 40%); // #959597
$color-gray-900: hsl(240, 1%, 29%); // #4A4A4C

$color-black: hsl(0, 0%, 0%);

/*  Animations
    Global to keep animations consistent
    ========================================================================= */

$base-animation-duration: 100ms !default;
$base-animation-timing: cubic-bezier(
  0.55,
  0,
  0.1,
  1
) !default; // Google swift bezier
$base-animation: $base-animation-duration $base-animation-timing;

/*  Responsive
    Used in conjunction with Sass MQ: https://sass-mq.github.io/sass-mq/
    ========================================================================= */

$mq-base-font-size: $supple-font-size;

/**
 * Name your breakpoints in a way that creates a ubiquitous language
 * across team members. It will improve communication between
 * stakeholders, designers, developers, and testers.
 */
$mq-breakpoints: (
  sm: 480px,
  md: 640px,
  lg: 960px,
  xl: 1280px,
);

/**
 * If you want to display the currently active breakpoint in the top
 * right corner of your site during development, add the breakpoints
 * to this list, ordered by width, e.g. (mobile, tablet, desktop).
 */
$mq-show-breakpoints: ();

$supple-breakpoint-has-layout-fit: (
  from: sm md lg xl,
  until: md lg xl,
);

$supple-breakpoint-has-layout-fill: (
  from: sm md lg xl,
  until: md lg xl,
);

$supple-breakpoint-has-spacing: (
  from: md lg xl,
  until: md lg xl,
);

$supple-breakpoint-has-fractions: (
  from: md lg xl,
  until: md lg xl,
);

$supple-breakpoint-has-hidden: (
  from: md lg xl,
  until: md lg xl,
);

/**
 * Border
 */
$border-radius-base: 6px;
$border-radius-medium: 8px;
$border-radius-card: 20px;
$border-radius-large: 30px;
$border-base-color: $color-gray-300;
$border-base: 1px solid $border-base-color;

/**
 * Shadow
 */
$box-shadow-base: 0 5px 20px transparentize($color-black, 0.9);
$box-shadow-base-right: 10px 0 20px transparentize($color-black, 0.9);
$box-shadow-base-left: -10px 0 20px transparentize($color-black, 0.9);
$box-shadow-tiny: 0 0 1px $color-gray-900;

/**
 * A11y
 */
$a11y-size: 48px;
$collapsable-panel-width: 3rem;

/**
 * Sidebar
 */
$sidebar-width: 320px;

:root {
  --color-brand: #{$color-blue-500};
  --color-brand-secondary: #{$color-green-500};
  --color-button-text: #{$color-white};
  // defaults to big touch targets
  --target-base-height: #{$a11y-size};
  --target-small-height: var(--target-base-height);
  --target-tiny-height: var(--target-base-height);
  --vertical-spacing: 4rem;

  // slightly smaller buttons for certain devices
  @media (pointer: fine) {
    --target-base-height: 45px;
    --target-small-height: 40px;
    --target-tiny-height: 1.5rem;
    --vertical-spacing: 7.5rem;
  }
}
