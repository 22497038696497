/**
 * Umbrella link utility
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.u-umbrella-link {
  text-decoration: inherit;

  &:hover,
  &:focus {
    color: inherit;
    outline: none;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
  }
}
.u-umbrella-link-container {
  position: relative;
}
/**
 * ======================================
 * Modifiers
 * ======================================
 */
