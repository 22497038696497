/**
 * Price table section
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.c-price-table {
  background-color: $color-blue-500;
  color: $color-white;
  padding: var(--vertical-spacing) 0;
  overflow: hidden;
}

/**
* ======================================
* Elements
* ======================================
*/

.c-price-table__background-image {
  color: $color-white;
  opacity: 0.2;
  position: absolute;
  left: 0;
  top: 70%;
  width: 400px;
  height: auto;
  transform: translate(-45%, -50%);
}

.c-price-table__container {
  position: relative;
}

.c-price-table__title {
  line-height: 1.25;
  font-weight: 800;
  grid-column: 1 / -1;
  text-align: center;

  span {
    color: $color-yellow-500;
    display: block;
  }
}

.c-price-table__container {
  display: grid;
  gap: 1.5rem;

  @include mq($from: lg) {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}

.c-price-table__card {
  --card-horizontal-padding: 2.5rem;
  --card-vertical-padding: 2rem;

  background-color: $color-blue-100;
  border-radius: $border-radius-card;
  padding: var(--card-vertical-padding) var(--card-horizontal-padding);
  color: $color-blue-700;
  overflow: hidden;
  position: relative;
  z-index: 1;

  @include mq($from: lg) {
    grid-column: span 6;
  }

  @include mq($from: xl) {
    grid-column: span 5;
  }
}

.c-price-table__card-title {
  @include mq($from: md) {
    font-size: 1.5rem;
    font-weight: 800;
  }
}

.c-price-table__price {
  margin-bottom: 0;
  max-width: unset;

  span {
    font-weight: 800;
    font-size: 1.5rem;

    @include mq($from: md) {
      font-size: 2.25rem;
    }
  }
}

.c-price-table__card-list {
  --line-height: 2;

  list-style: none;
  padding-left: 0;

  li {
    position: relative;
    padding-left: 1.5em;
    line-height: var(--line-height);

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: calc(0.5em * var(--line-height));
      transform: translateY(-50%);
      width: 1em;
      height: auto;
      aspect-ratio: 18 / 16;
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='16' viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.23518 16C12.7833 16 16.4704 12.4183 16.4704 8C16.4704 3.58172 12.7833 0 8.23518 0C3.68702 0 0 3.58172 0 8C0 12.4183 3.68702 16 8.23518 16Z' fill='%23C9D9FF'/%3E%3Cpath d='M5.19776 5.17478C4.5101 4.50675 3.39518 4.50675 2.70751 5.17478C2.01985 5.8428 2.01985 6.92588 2.70751 7.59391L7.15977 11.919C7.84743 12.587 8.96235 12.587 9.65002 11.919C10.3377 11.251 10.3377 10.1679 9.65002 9.49989L5.19776 5.17478Z' fill='%234444F1'/%3E%3Cpath d='M14.9901 1.89899L7.15459 9.51069C6.46693 10.1787 6.46693 11.2618 7.15459 11.9298C7.84225 12.5978 8.95717 12.5978 9.64483 11.9298L17.4803 4.31812C18.168 3.65009 18.168 2.56701 17.4803 1.89899C16.7926 1.23096 15.6777 1.23096 14.9901 1.89899Z' fill='%234444F1'/%3E%3C/svg%3E");
    }
  }
}

.c-price-table__cta {
  display: flex;
  margin-bottom: 1.5rem;
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */

.c-price-table__card--main {
  grid-row: span 5;
  background-color: $color-white;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;

  @include mq($from: xl) {
    grid-column: 2 / span 5;
  }

  .c-price-table__price:not(:last-child) {
    margin-bottom: 2rem;
  }

  .c-price-table__price:last-child {
    background-color: $color-blue-100;
    margin-left: calc(var(--card-horizontal-padding) * -1);
    margin-right: calc(var(--card-horizontal-padding) * -1);
    padding: var(--card-vertical-padding) var(--card-horizontal-padding);
    margin-top: auto;
  }

  .c-price-table__card-title {
    @include mq($from: md) {
      font-size: 2rem;
    }
  }
}
