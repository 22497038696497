.u-text-large {
  font-size: $font-size-large;
}

.u-text-base {
  font-size: $font-size-base;
}

.u-text-small {
  font-size: $font-size-small;
}

.u-text-h1 {
  font-size: $font-size-h1;
}

.u-text-h2 {
  font-size: $font-size-h2;
}

.u-text-h3 {
  font-size: $font-size-h3;
}

.u-text-semi-bold {
  font-weight: 600;
}

.u-text-bold {
  font-weight: 700;
}

.u-text-extra-bold {
  font-weight: 800;
}

.u-text-retain-none {
  max-width: none;
}

.u-text-retain {
  max-width: 70ch;
}

.u-text-retain-small {
  max-width: 45ch;
}

.u-text-break-word {
  overflow-wrap: break-word;
}

.u-text-font-base {
  font-family: $font-family-base;
}

.u-text-gray-500 {
  color: $color-gray-500;
}

.u-text-align-right {
  text-align: right;
}

.u-text-align-center {
  text-align: center;
}

.u-text-uppercase {
  text-transform: uppercase;
}

.u-text-capitalize {
  text-transform: capitalize;
}
