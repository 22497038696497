/**
 * Video
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.c-video {
  width: 100%;
  aspect-ratio: 16 / 9;
  position: relative;
}

/**
 * ======================================
 * Elements
 * ======================================
 */
.c-video__iframe {
  height: 100%;
  width: 100%;
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */
