/**
 * Form
 * Basic styling for form fields, labels, etc.
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.c-form {
  // Empty for reference
}

/**
 * ======================================
 * Elements
 * ======================================
 */

.c-form__row {
  margin-bottom: $supple-space-base;
}

.c-form__input-container {
  position: relative;
}

.c-form__icon {
  position: absolute;
  right: calc(var(--target-base-height) / 2);
  top: 50%;
  transform: translate(50%, -50%);
  width: calc(var(--target-base-height) / 2);
  height: calc(var(--target-base-height) / 2);
  color: currentColor;

  input:hover ~ &,
  input:focus ~ & {
    color: $color-blue-500;
  }
}

.c-form__pending-spinner {
  position: absolute;
  right: calc(var(--target-base-height) / 2);
  top: 50%;
  animation: pending-spinner 750ms linear infinite;
  color: $color-gray-900;
  border-radius: 50%;
  stroke-dasharray: 10;
  stroke-dashoffset: 2;
  transition: all 150ms linear;
}

@keyframes pending-spinner {
  from {
    transform: translate(50%, -50%);
  }

  to {
    transform: translate(50%, -50%) rotate(1turn);
  }
}

/**
 * Element: error
 */
.c-form__error {
  color: $color-red-500;
}

.c-form__label {
  max-width: 70ch;
  margin-bottom: $supple-space-tiny;
}

.c-form__hint {
  color: $color-gray-500;
  font-weight: normal;
}

.c-form__file-upload-container {
  position: relative;

  > .c-input-text {
    padding-right: $a11y-size;
  }
}

.c-form__empty-file-upload {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-54%); // Visually align

  &:focus {
    outline: none;
  }
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */

.c-form__input--error,
.c-autocomplete--error .c-autocomplete__control {
  outline: none !important;
  box-shadow: 0 0 3px 1px $color-red-500;
}

.c-form__hint--under-input {
  margin-top: $supple-space-tiny;
}

.c-form__row--full-width,
.c-form__row--rte {
  max-width: none;
}
