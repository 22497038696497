/**
 * Label element
 */

/**
 * 1. If the label is connected to an input, have user feedback for interaction
 */
label {
  display: block;
  font-weight: bold;

  &[for] {
    cursor: pointer; /* [1] */
  }

  &[hidden] {
    display: none;
  }

  :disabled > & {
    cursor: not-allowed;
  }

  small {
    font-weight: normal;
  }
}
