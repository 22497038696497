/**
 * Page elements
 */

html {
  overflow: auto;
}

body {
  margin: 0;
}

.c-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.c-main {
  flex: 1 1 100%;
}
