/**
 * Toggle
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.c-toggle {
  --toggle-height: 3rem;
  display: flex;
  flex-flow: row nowrap;
  border: 1px solid $color-gray-300;
  border-radius: calc(var(--toggle-height) / 2);
  height: var(--toggle-height);
  background-color: $color-white;
  align-items: center;
}

/**
 * ======================================
 * Elements
 * ======================================
 */
.c-toggle__label {
  padding: 0 $supple-space-base;
  height: var(--toggle-height);
  line-height: var(--toggle-height);
  border: 1px solid transparent;
  transition: $base-animation;
  display: flex;
  flex-wrap: row nowrap;
  align-items: center;
  gap: $supple-space-tiny;
  border-radius: calc(var(--toggle-height) / 2);

  .c-toggle__group:not(:first-child) & {
    margin-left: -$supple-space-tiny;
  }

  .c-toggle__input:checked + & {
    background-color: $color-blue-50;
    border: solid 1px $color-blue-500;
  }
}

.c-toggle__icon {
  width: 1.5em;
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */
