/**
 * ======================================
 * Block
 * ======================================
 */

.c-ie-warning {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100000;
  background: rgba($color-blue-700, 0.95);
  display: none;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: block;
  }
}

/**
 * ======================================
 * Elements
 * ======================================
 */

.c-ie-warning__modal {
  background: $color-white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100vw - #{2 * $supple-space-base});
  max-width: calc(70ch + #{2 * $supple-space-base});
  max-height: calc(100vh - #{2 * $supple-space-base});
}

.c-ie-warning__list {
  margin: 0;

  li + li {
    margin-top: 0.25em;
  }
}
