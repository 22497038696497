/**
 * Filter hero
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.c-filter-hero {
  background-color: $color-blue-500;
  color: $color-white;
  overflow: hidden;
}

/**
 * ======================================
 * Elements
 * ======================================
 */
.c-filter-hero__container {
  padding-top: var(--vertical-spacing);
  padding-bottom: var(--vertical-spacing);
  position: relative;

  display: grid;
  gap: 2rem;

  @include mq($from: md) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: center;
  }
}

.c-filter-hero__visual {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(55%, 55%);
  max-width: 50%;
  height: 150%;
  width: auto;
  z-index: 2;
}

.c-filter-hero__title {
  @include mq($from: lg) {
    font-size: 4rem;
  }

  span {
    display: block;
    color: $color-yellow-500;
  }
}

.c-filter-hero__text {
  a {
    color: $color-yellow-500;
    text-decoration: underline;
  }
  margin-bottom: 0.75em;
}

.c-filter-hero__form-container {
  position: relative;
  z-index: 1;
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */

.c-filter-hero--home {
  .c-filter-hero__visual {
    @include mq($until: xl) {
      display: none;
    }

    .hero-visual-lamp {
      display: none;
    }
  }

  .c-filter-hero__container {
    @include mq($from: xl) {
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: $color-gray-100;
        border-radius: 50%;
        height: auto;
        aspect-ratio: 1 / 1;
        width: 70%;
        transform: translate(60%, 50%);
      }
    }
  }
}

.c-filter-hero--employers {
  .c-filter-hero__visual {
    @include mq($until: md) {
      display: none;
    }

    right: 0;
    transform: translate(0, 50%);
  }

  .c-filter-hero__container {
    @include mq($from: md) {
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: $color-gray-100;
        border-radius: 50%;
        height: auto;
        aspect-ratio: 1 / 1;
        width: 70%;
        transform: translate(30%, 50%);
      }
    }
  }
}
