/**
 * ======================================
 * Block
 * ======================================
 */

.c-usps {
  margin: $supple-space-large 0;

  @include mq($until: md) {
    text-align: center;
  }
}

/**
 * ======================================
 * Elements
 * ======================================
 */

.c-usps__heading {
  margin: 0;

  @include mq($from: md) {
    margin-bottom: $supple-space-base;
  }
}

.c-usps__list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.c-usps__usp {
  margin: 0 auto;
  max-width: 35ch;

  @include mq($from: md) {
    margin: 0;
    max-width: none;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-areas: 'icon description';
    gap: $supple-space-base;
    align-items: center;

    & + & {
      margin-top: $supple-space-base * 1.5;
    }
  }
}

.c-usps__icon {
  margin: $supple-space-base auto;
  width: 3rem;
  height: 3rem;

  @include mq($from: md) {
    grid-area: icon;
    align-self: start;
    margin: 0;
  }
}

.c-usps__description {
  margin-bottom: 0;
  grid-area: description;
}
