/*
 * 400 - Regular
 * 600 - Semibold
 * 700 - Bold
 * 800 - Extra Bold
 */
$font-path: '/fonts/';

@font-face {
  font-family: 'Nunito Sans';
  src: url($font-path + 'hinted-NunitoSans-Regular.woff2') format('woff2'),
    url($font-path + 'hinted-NunitoSans-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url($font-path + 'hinted-NunitoSans-Italic.woff2') format('woff2'),
    url($font-path + 'hinted-NunitoSans-Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url($font-path + 'hinted-NunitoSans-SemiBold.woff2') format('woff2'),
    url($font-path + 'hinted-NunitoSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url($font-path + 'hinted-NunitoSans-SemiBoldItalic.woff2')
      format('woff2'),
    url($font-path + 'hinted-NunitoSans-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url($font-path + 'hinted-NunitoSans-Bold.woff2') format('woff2'),
    url($font-path + 'hinted-NunitoSans-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url($font-path + 'hinted-NunitoSans-BoldItalic.woff2') format('woff2'),
    url($font-path + 'hinted-NunitoSans-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url($font-path + 'hinted-NunitoSans-ExtraBold.woff2') format('woff2'),
    url($font-path + 'hinted-NunitoSans-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url($font-path + 'hinted-NunitoSans-ExtraBoldItalic.woff2')
      format('woff2'),
    url($font-path + 'hinted-NunitoSans-ExtraBoldItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}
