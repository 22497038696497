/**
 * ======================================
 * Block
 * ======================================
 */

.u-list-check,
.u-list-check ul {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    position: relative;
    padding-left: 1.5em;

    &:not(:last-child) {
      margin-bottom: 0.75em;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0.75em;
      transform: translateY(-50%);
      background: url(/static/svg/sidebar-usp-marker.svg) no-repeat center;
      background-size: contain;
      width: 1.125em;
      height: 1.125em;
    }
  }
}
