/**
 * Search terms
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.c-search-terms {
  padding: 4rem $supple-space-small;
  text-align: center;

  @include mq($from: md) {
    padding: 7.5rem $supple-space-base;
  }
}

/**
 * ======================================
 * Elements
 * ======================================
 */

.c-search-terms__title {
  color: $color-blue-700;
  margin-bottom: 2rem;

  @include mq($from: lg) {
    margin-bottom: 3rem;
  }
}

.c-search-terms__list {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem 1rem;
  justify-content: center;

  @include mq($from: lg) {
    gap: 1.5rem 1.25rem;
  }
}

.c-search-terms__link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: $color-gray-900;
  padding: 0.75rem 1rem;
  background-color: $color-gray-100;
  border-radius: $supple-space-huge;

  .c-search-terms__item:first-child & {
    background-color: $color-blue-500;
    color: $color-white;
  }

  @include mq($from: lg) {
    padding: 1.125rem 1.25rem;
  }

  &:hover,
  &:focus {
    text-decoration: underline;
    color: $color-blue-500;
  }
}

.c-search-terms__icon {
  margin-right: $supple-space-tiny;

  @include mq($from: lg) {
    width: 1.25rem;
    height: 1.25rem;
  }
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */
