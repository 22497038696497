/**
 * Dialog
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.c-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color-gray-900, 0.1);
  z-index: 99;
}

.c-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90vw;
  max-width: 33em;
  max-height: 90vh;
  background-color: $color-white;
  border: $border-base;
  border-radius: $border-radius-base;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  // Keep these weird values, these remove the blurry effect
  transform: translate(-50%, -50.2%);
  overflow: hidden;
  padding: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
}

/**
 * ======================================
 * Elements
 * ======================================
 */
.c-dialog__heading,
.c-dialog__content,
.c-dialog__notification {
  padding: $supple-space-base;
}

.c-dialog__notification {
  padding-top: 0;
}

.c-dialog__heading,
.c-dialog__actions {
  flex: 0 0 auto;
}

.c-dialog__heading > *:last-child {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.c-dialog__content {
  flex: 1 1 auto;
  overflow: auto;
}

.c-dialog__title {
  margin-bottom: $supple-space-small;
}

.c-dialog__description {
  margin: 0;
}

.c-dialog__actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-top: $border-base;
  background-color: $color-gray-100;
  padding: $supple-space-small;
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */
