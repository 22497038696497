/**
 * pagepart-text-image
 */

/**
 * ======================================
 * Block
 * ======================================
 */

/**
 * ======================================
 * Elements
 * ======================================
 */

.c-pagepart-text-image__heading {
  margin-top: 1.5rem;
}

.c-pagepart-text-image__image {
  box-shadow: $box-shadow-base;
  span {
    display: block !important;
  }
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */
