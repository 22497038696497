/**
 * Subsription-item
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.c-subscription-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: $border-base;
  border-radius: $border-radius-base;
  padding: 1.5rem;
  width: 100%;
  min-height: 37rem;

  @include mq($from: sm) {
    padding: 2rem 3rem 1.5rem;
  }

  @include mq($from: xl) {
    padding: 2rem 2rem 1.5rem;
  }
}

/**
* ======================================
* Elements
* ======================================
*/

.c-subscription-item__top {
  display: flex;
  flex-direction: column;
}

.c-subscription-item__title {
  text-align: center;
  height: 2rem;
}

.c-subscription-item__contact-section {
  display: flex;
  flex-direction: column;
}

.c-subscription-item__phone-link {
  justify-content: center;
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */
