/**
 * ======================================
 * Block
 * ======================================
 */

$breadcrumb-crumb-spacing: 3ch;

.c-breadcrumbs {
  //
}

/**
 * ======================================
 * Elements
 * ======================================
 */

.c-breadcrumbs__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: $supple-space-base 0;
}

.c-breadcrumbs__crumb {
  position: relative;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
}

.c-breadcrumbs__link,
.c-breadcrumbs__chevron {
  flex: 0 0 auto;
}

.c-breadcrumbs__link,
.c-breadcrumbs__span {
  text-decoration: none;
  min-width: $a11y-size;
  padding: calc((#{$a11y-size} - 1.5em) / 2) 0;
}

.c-breadcrumbs__chevron {
  color: $color-gray-300;
  width: 1;
  height: 1ex;
  margin: 0 calc(#{$breadcrumb-crumb-spacing / 2} - 1ex);
}
