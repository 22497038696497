/**
 * Supple layout additions
 */

/**
 * ======================================
 * Modifiers
 * ======================================
 */
.o-layout--justify-space-between {
  justify-content: space-between;
}

.o-layout--justify-center {
  justify-content: center;
}

.o-layout--align-center {
  align-items: center;
}

.o-layout--gap-base {
  gap: $supple-space-base;
}

.o-layout--gap-small {
  gap: $supple-space-small;
}

.o-layout--gutter-large\@from-md {
  @include mq($from: md) {
    margin-left: -$supple-space-large;

    > .o-layout__cell {
      padding-left: $supple-space-large;
    }
  }
}
