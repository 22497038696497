/**
 * Video section
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.c-video-section {
  background-color: $color-blue-50;
  color: $color-blue-700;
  padding-bottom: var(--vertical-spacing);
  position: relative;
}

/**
 * ======================================
 * Elements
 * ======================================
 */

.c-video-section__anchor {
  position: absolute;
  top: calc(-1 * var(--vertical-spacing));
}

.c-video-section__video {
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: $border-radius-large;
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */
