/**
 * Input text 
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.c-input-text {
  @include shared-input;
  font-size: $font-size-base;
  font-weight: 400;
  border-color: $color-gray-200;
  background-color: $color-white;
  color: $color-black;
  width: 100%;

  &:hover,
  &:focus {
    border-color: $color-blue-500;
  }

  &:focus {
    outline: none;
  }

  &.is-invalid,
  &:not(:empty):invalid {
    border-color: $color-red-500 !important; /* [1] */
  }

  &.is-valid {
    border-color: $color-green-500 !important; /* [1] */
  }

  /**
   * State: disabled
   * 1. Shit needs to be immutable, disabled is disabled
   */
  &.is-disabled,
  &:disabled,
  &:read-only {
    color: $color-gray-500; /* [1] */
    background-color: $color-gray-100; /* [1] */
    cursor: not-allowed;

    &:hover,
    &:focus {
      border-color: $color-gray-200;
    }
  }

  &[type='file']:read-only {
    cursor: pointer;
  }

  &::placeholder {
    color: $color-gray-300;
  }

  &[data-pending] {
    padding-right: var(--target-base-height);
  }

  &[hidden] {
    display: none;
  }

  .whitelabel-theme & {
    &:hover,
    &:focus {
      border-color: $color-gray-500;
    }
  }
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */
textarea.c-input-text {
  max-width: 100%;
  height: calc(var(--target-base-height) * 2);
  transition: border-color;
}

textarea.c-input-text--large-textarea {
  height: 11.5rem;
}

// transform zipcode fields to uppercase
.c-input-text[name='zipcode'] {
  text-transform: uppercase;
}

.c-input-text--clean {
  background-color: transparent;
  border: none;
  border-bottom: $border-base;
  border-bottom-color: $color-blue-200;
  border-radius: 0;
  color: $color-white;

  &::placeholder {
    color: $color-white;
    font-style: italic;
  }

  &:disabled,
  &.is-disabled {
    background-color: transparent;
    border: none;
    border-bottom: $border-base;
    border-bottom-style: dotted;
    font-style: italic;
  }

  &:hover,
  &:focus {
    border-bottom: $border-base;
    border-bottom-color: $color-white;
  }
}

.c-input-text--copy {
  &:read-only {
    cursor: copy;
  }
}

.c-input-text--has-icon {
  padding-right: var(--target-base-height);
}

.c-input-text--autocomplete-placeholder {
  border: 1px 4px solid hsl(0, 0%, 80%);
  border-radius: 4px;
  min-height: 45px;
}
