/**
 * Filter form
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.c-filter-form {
  background-color: $color-white;
  padding: 3rem 1rem;
  border-radius: $border-radius-large;
  box-shadow: 0px 10px 40px 0px #0000001a;
  color: $color-blue-700;

  @include mq($from: md) {
    padding: 3rem 1.5rem;
  }
}

/**
 * ======================================
 * Elements
 * ======================================
 */
.c-filter-form__title {
  font-size: 1.75rem;
  line-height: 1.25;
  font-weight: 800;

  svg {
    display: inline-block;
    transform: translateY(30%);
    margin-right: 1rem;
  }
}

// targeting classes outside of the component because they need specific styling for the filter form
// Used for the redesign of the homepage search bar
.c-filter-form__place-search .c-autocomplete__control,
.c-filter-form__autocomplete {
  background-color: $color-gray-100;
  color: $color-black;
  font-weight: 500;
  border-radius: $border-radius-card !important;
  border: none !important;
  padding: 1rem 1.5rem;

  .c-autocomplete__value-container,
  .c-autocomplete__input-container {
    padding: 0;
    margin: 0;
  }

  .c-autocomplete__indicator {
    padding-top: 0;
    padding-bottom: 0;
  }

  svg {
    color: $color-black;
  }
}

.c-filter-form__vacancy-link {
  font-weight: bold;
  color: $color-blue-700;
  margin-top: 1.5rem;

  &:hover,
  &:focus {
    color: $color-blue-500;
  }
}

.c-filter-form__cta-container {
  display: flex;
  flex-direction: column;
  width: max-content;
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */
