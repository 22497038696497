/**
 * ======================================
 * Block
 * ======================================
 */

.c-woty {
  display: grid;
  grid-template: auto / 1fr 80px auto 1fr;
  gap: $supple-space-base;
  margin-bottom: $supple-space-base;
}

/**
 * ======================================
 * Elements
 * ======================================
 */

.c-woty__image {
  grid-area: 1/2/2/3;
}

.c-woty__text {
  grid-area: 1/3/2/4;
  font-size: 0.875rem;
  align-self: center;
}
