/**
 * ======================================
 * Block
 * ======================================
 */

.c-dashboard-nav {
  --spacing-vertical: calc(
    #{$supple-space-base} - (var(--target-base-height) - 1.5em) / 2
  );
  padding-top: var(--spacing-vertical);
  padding-bottom: var(--spacing-vertical);
  margin-bottom: $supple-space-large;
}
