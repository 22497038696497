/**
 * ======================================
 * Block
 * ======================================
 */

.c-hero {
  position: relative;
  background: $color-gray-300;
  width: 100%;
  padding-bottom: 100% * (540/1920);
}
