/**
 * Autocomplete
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.c-autocomplete {
  position: relative;
  display: inline-block;
  width: 100%;
}

/**
 * ======================================
 * Elements
 * ======================================
 */

.c-autocomplete__control {
  @include shared-input; /* [1] */
  width: 100%;
  box-shadow: none;
  min-height: var(--target-base-height) !important;
  cursor: text !important;
  border-color: $color-gray-500 !important;
  border-radius: 6px !important;
  border-style: solid !important;
  border-width: 1px !important;

  &:hover,
  &:focus {
    border-color: $color-blue-500 !important;
    box-shadow: none !important;
    outline: none;
  }

  &::placeholder {
    color: $color-gray-300;
  }
}

.c-autocomplete__placeholder {
  color: $color-gray-300 !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  left: 12px;
  right: 8px;
}

.c-autocomplete__indicator-separator {
  display: none;
}

.c-autocomplete__indicators {
  color: $color-white !important;
  padding-right: $supple-space-tiny;
  transition: color $base-animation-duration;
}

.c-autocomplete__indicator {
  cursor: pointer;
}

.c-autocomplete__menu {
  color: $color-black;
}

.c-autocomplete__input > input {
  opacity: 1 !important;
  width: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 0 8px 0 14px !important;
}

.c-autocomplete__input {
  opacity: 1 !important;
}

.c-autocomplete__icon {
  position: absolute;
  right: $supple-space-tiny;
  top: 50%;
  transform: translateY(-50%);
  color: $color-blue-500;
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */
.c-autocomplete__control--menu-is-open {
  border-color: $color-blue-500;
}

.c-autocomplete__control--is-focused {
  border-color: $color-blue-500 !important;
  box-shadow: none !important;

  .c-autocomplete__indicators {
    color: $color-blue-500 !important;
  }
}

.c-autocomplete--up {
  .c-autocomplete__menu {
    top: -$supple-space-small;
    bottom: auto;
    transform: translateY(-100%);
  }
}

.c-autocomplete--invalid {
  .c-autocomplete__control {
    border-color: $color-red-500 !important;
    box-shadow: 0 0 2px 1px rgba($color-red-500, 0.5);
  }
}

.c-autocomplete--branch-filter {
  color: $color-white;

  .c-autocomplete__control {
    background: none;
    border: none;
    border-bottom: $border-base;
    border-bottom-color: $color-blue-200;
    border-radius: 0;

    &:hover,
    &:focus {
      border-bottom-color: $color-white;
    }
  }

  .c-autocomplete__placeholder {
    color: $color-white;
    font-weight: 600;
  }

  .c-autocomplete__single-value {
    color: $color-white;
  }

  .c-autocomplete__input {
    color: $color-white;
    background-color: transparent;
    border: none !important;
    border-bottom: $border-base;
    border-bottom-color: $color-white;
    border-radius: 0;

    &:hover,
    &:focus {
      border-bottom-color: $color-white;
    }
  }

  .c-autocomplete__control--is-focused {
    border-color: $color-white !important;

    .c-autocomplete__indicators {
      color: $color-white !important;
    }
  }
}

.c-autocomplete--place-search {
  .c-autocomplete__control {
    padding: 0;
  }
}
