/**
 * ======================================
 * Block
 * ======================================
 */

.c-popup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  background: rgba($color-blue-900, 0.25);
  backdrop-filter: blur(2px);
}

/**
 * ======================================
 * Elements
 * ======================================
 */

.c-popup__dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100vw - #{2 * $supple-space-base});
  max-width: 80ch;
  max-height: calc(100vh - #{2 * $supple-space-large});
  background: $color-white;
  border: 1px solid $color-gray-300;
  border-radius: $border-radius-base;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.25);
}

.c-popup__header {
  padding: $supple-space-base;
  display: grid;
  grid-template: auto / 1fr auto;
  gap: $supple-space-base;
  align-items: start;
}

.c-popup__heading {
  align-self: center;
  margin: 0;
}

.c-popup__content {
  padding: $supple-space-base;
}
