/**
 * ======================================
 * Block
 * ======================================
 */

.c-sidebar {
  position: sticky;
  top: $supple-space-large;
  border-color: $color-gray-100;
}

/**
 * ======================================
 * Elements
 * ======================================
 */

.c-sidebar__header {
  background: $color-gray-100;
  margin: -$supple-space-base;
  margin-bottom: $supple-space-base;
  padding: $supple-space-base;
  text-align: center;
}

.c-sidebar__heading {
  margin: 0 0 $supple-space-small;
}

.c-sidebar__countdown {
  text-align: center;
}

.c-sidebar__countdown-counter {
  margin: $supple-space-small 0 $supple-space-base;
  padding: calc(#{$supple-space-small} - 0.25em) $supple-space-small;
  border: 1px solid $color-gray-200;
  color: $color-green-500;
  font-weight: 700;
  font-size: 1.125em; //because card's fluid font-size
}

.c-sidebar__security {
  border: 1px solid rgba($color-gray-200, 0.32);
  background: rgba($color-gray-100, 0.3);
  padding: $supple-space-small $supple-space-base;
  text-align: center;
  margin-bottom: $supple-space-base;
}

.c-sidebar__security-inner {
  display: inline-grid;
  grid-template: auto / auto 1fr;
  gap: $supple-space-small;
  align-items: center;
  text-align: left;
}

.c-sidebar__shield {
  color: $color-green-500;
}

.c-sidebar__card {
  border-color: $color-gray-200;

  &:not(:last-child) {
    margin-bottom: $supple-space-base;
  }
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */

@media (max-width: ($sidebar-width  * 3) - 1) {
  .c-sidebar {
    background: $color-white;
    border: none;
    border-radius: 0;
    margin: $supple-space-large -1.5rem -#{$supple-space-large};
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.07);
  }

  .c-sidebar__header {
    background: none;
    margin: 0;
    padding: 0;

    & ~ * {
      display: none;
    }
  }

  .c-sidebar__heading {
    @include supple-visually-hidden;
  }
}
