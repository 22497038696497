/**
 * Selected companies
 */

/**
 * ======================================
 * Block
 * ======================================
 */

.c-selected-companies {
  padding: var(--vertical-spacing) 0;
  color: $color-blue-700;
  background-color: $color-blue-50;
}

/**
 * ======================================
 * Elements
 * ======================================
 */

.c-selected-companies__title {
  line-height: 3rem;
  text-align: center;
  margin: 0 auto 2rem auto;
  font-weight: 800;
  line-height: 1.25;
  font-size: 1.75rem;

  @include mq($from: md) {
    margin-bottom: 3.5rem;
    font-size: 2.375rem;
  }
}

.c-selected-companies__list {
  padding-left: 0px;
  margin-bottom: 0;
  list-style: none;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);

  @include mq($from: sm) {
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }

  @include mq($from: md) {
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
  }

  @include mq($from: lg) {
    grid-template-columns: repeat(5, 1fr);
  }

  @include mq($from: xl) {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}

.c-selected-companies__list-item {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-white;
  box-shadow: 0px 4px 40px 0px #0000000d;
  border-radius: 50%;
  overflow: hidden;
  aspect-ratio: 1 / 1;
  padding: 1rem;
}

.c-selected-companies__image {
  width: 100%;
  height: auto;
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */
