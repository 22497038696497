/**
 * ======================================
 * Block
 * ======================================
 */

.u-pending {
  position: relative;
  display: flex;

  &::after {
    content: '';
    display: block;
    width: 1ch;
    margin-left: 1ch;
    background: url(/static/svg/spinner.svg) no-repeat center;
    background-size: contain;
    animation: spinner 500ms linear infinite;
    transition: all 250ms ease-in-out;
    opacity: 0;
  }
}

@keyframes spinner {
  to {
    transform: scale(1) rotate(1turn);
  }
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */

.u-pending--is-pending::after {
  opacity: 1;
}
