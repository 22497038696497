/**
 * ======================================
 * Block
 * ======================================
 */

.c-job-posting-list {
  position: relative;
}

/**
* ======================================
* Elements
* ======================================
*/

.c-job-posting-list__toggle {
  @include mq($until: xl) {
    display: none !important;
  }
}

.c-job-posting-list__preview {
  position: relative;
  scroll-margin-top: 12.5rem;
}

.c-job-posting-list__split-container {
  @include mq($from: xl) {
    display: grid;
    grid-template-columns: 480px 1fr;
    gap: $supple-space-large;
  }
}

.c-job-posting-list__detail {
  display: none;

  @include mq($from: xl) {
    display: block;
  }
}

.c-job-posting-list__list {
  transition: all 150ms ease-in-out;
}

.c-job-posting-list__heading {
  --heading-scroll-offset: #{$supple-space-large};
  --heading-scroll-multiply: 1;

  @include mq($from: md) {
    --heading-scroll-offset: #{-$supple-space-large};
    --heading-scroll-multiply: -1;
  }
}

.c-job-posting-list__ghost {
  height: 156px;
  pointer-events: none;
  user-select: none;

  .c-card__title,
  .c-properties__property-icon,
  .c-properties__property-value {
    background: currentColor;
    opacity: 0.5;
    overflow: hidden;
  }

  .c-card__title {
    padding: 0;
    flex: 0 1 auto;
  }

  .c-properties__property-value {
    place-self: start;
  }
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */

.c-job-posting-list--loading {
  .c-job-posting-list__list {
    filter: grayscale(75%) blur(2px);
    pointer-events: none;
  }
}
