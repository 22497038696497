/**
 * ======================================
 * Block
 * ======================================
 */

.c-job-posting-detail {
  //
}

/**
 * ======================================
 * Elements
 * ======================================
 */

.c-job-posting-detail__summary {
  margin: $supple-space-tiny 0;
}

.c-job-posting-detail__date {
  color: $color-gray-300;
  font-size: 0.875rem;
  display: inline-block;
  margin-right: 2ch;
}

.c-job-posting-detail__extra-properties {
  margin-bottom: $supple-space-small;
  color: $color-gray-300;
}

.c-job-posting-detail__logo {
  margin: $supple-space-large 0;
}

.c-job-posting-detail__description {
  ul:not([class]),
  ol:not([class]) {
    list-style-type: none;
    padding: 0;
    margin-left: 0;
    max-width: 70ch;

    li {
      position: relative;
      padding-left: 2em;

      &:not(:last-child) {
        margin-bottom: 0.25em;
      }

      &::before {
        position: absolute;
        left: 0;
        width: 1em;
        height: 1em;
      }
    }
  }

  ul:not([class]) {
    li {
      &::before {
        content: '';
        top: 0.75em; // line-height / 2
        transform: translateY(-50%);
        background: url(/static/svg/list-bullet.svg) no-repeat center;
        background-size: contain;
      }
    }
  }

  ol:not([class]) {
    counter-reset: section;

    li {
      &::before {
        counter-increment: section;
        content: counters(section, '.') '.';
        font-weight: bold;
      }
    }
  }
}

.c-job-posting-detail__extra-properties {
  font-size: 0.875rem;

  .c-properties__property:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    width: 1px;
    height: 1em;
    background: $color-gray-200;
    transform: translate(#{$supple-space-base / 2}, -50%);
  }
}
