/**
 * ======================================
 * Block
 * ======================================
 */
.c-footer {
  position: relative;
  padding: $supple-space-large * 1.5 0 $supple-space-base;
  background: $color-blue-500;
  color: $color-white;
  overflow: hidden;
  margin-top: 3rem;

  a {
    color: inherit;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  .whitelabel-theme & {
    background: $color-gray-100;
    color: $color-blue-500;
    padding: 0;
  }
}

/**
 * ======================================
 * Elements
 * ======================================
 */
.c-footer__content {
  position: relative;
  z-index: 1;
}

.c-footer__title {
  font-size: 1rem;
  margin-bottom: 2rem;
}

.c-footer__list {
  padding-left: 1rem;
}

.c-footer__list-item {
  &:not(:last-child) {
    margin-bottom: $supple-space-tiny;
  }
}

.c-footer__social {
  svg {
    transition: 0.3s $base-animation-timing;
  }

  &:hover,
  &:focus {
    svg {
      transform: scale(1.1);
    }
  }
}

.c-footer__bottom {
  padding: $supple-space-base 0 $supple-space-small;
  text-align: center;
  border-top: 1px solid rgba($color-white, 0.1);

  @include mq($until: md) {
    margin: 0 (-$supple-space-base);
  }
}
