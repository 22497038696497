.c-list {
  padding-left: 0;

  li {
    list-style-type: none;

    &::before {
      content: '\203A';
      margin-right: $supple-space-small;
      vertical-align: baseline;
    }
  }
}
