/**
 * Loading component
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.c-loading-container {
  position: relative;
}

.c-loading {
  position: relative;
  flex: 100% 1 1;
  min-height: 150px;
}

/**
 * ======================================
 * Elements
 * ======================================
 */
.c-loading__overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% + #{$supple-space-tiny * 2});
  width: calc(100% + #{$supple-space-tiny * 2});
  background-color: rgba($color-gray-900, 0.05);
  margin: -$supple-space-tiny;
  border-radius: $border-radius-base;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-loading__spinner {
  position: sticky;
  top: 240px;
  bottom: 120px;
  transform: translate(-50%, -50%);
  animation: pending-spinner 750ms linear infinite;
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */
.c-loading--absolute {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  min-height: auto;
}

.c-loading--combined-form {
  .c-loading__overlay {
    width: 100%;
    height: 100%;
    margin: 0;
  }
}
