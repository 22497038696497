/**
 * ======================================
 * Block
 * ======================================
 */

.c-pagination {
  margin: $supple-space-base 0;
  display: grid;
  gap: $supple-space-base;
  grid-template-columns: auto 1fr auto;
}

/**
 * ======================================
 * Elements
 * ======================================
 */

.c-pagination__summary {
  align-self: center;
  text-align: center;
}

.c-pagination__button {
  .c-button__icon {
    width: 1ex;
    height: 1ex;
  }

  @include mq($until: md) {
    .c-button__label {
      @include supple-visually-hidden;
    }
  }
}
