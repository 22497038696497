/**
 * Content grid section
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.c-content-grid {
  padding: var(--vertical-spacing) 0;
  color: $color-blue-700;
  overflow: hidden;
  position: relative;

  section + & {
    padding-top: 0;
  }
}

/**
 * ======================================
 * Elements
 * ======================================
 */

.c-content-grid__background-image {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(-30%, 40%);
  width: 110vw;
  height: auto;
  pointer-events: none;
  color: $color-white;
  opacity: 0.2;

  @include mq($from: md) {
    width: 80vw;
  }
}

.c-content-grid__container,
.c-content-grid__items {
  position: relative;
  z-index: 1;
}

.c-content-grid__container {
  @include mq($from: md) {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}

.c-content-grid__content {
  grid-column: 1 / -1;
  margin-bottom: 3rem;

  @include mq($from: lg) {
    grid-column: 2 / -2;
  }
}

.c-content-grid__label {
  font-size: 1.125rem;
  font-style: italic;
  background-color: $color-blue-500-transparent;
  padding: 0.5rem 1rem;
  border-radius: $border-radius-medium;
  width: fit-content;
  line-height: 1.55;
  margin-bottom: 1rem;

  @include mq($from: md) {
    margin-bottom: 1.5rem;
  }
}

.c-content-grid__title {
  white-space: pre-line;
  line-height: 1.25;
  font-size: 2rem;
  font-weight: 800;

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  @include mq($from: md) {
    font-size: 3.25rem;
  }
}

.c-content-grid__text {
  line-height: 1.55;
  font-size: 1.125rem;
  font-weight: 700;

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}

.c-content-grid__item {
  display: grid;
  gap: 1rem;
  width: 100%;
  grid-auto-flow: dense;

  &:not(:last-child) {
    margin-bottom: 2rem;

    @include mq($from: md) {
      margin-bottom: 0;
    }
  }

  @include mq($from: md) {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}

.c-content-grid__item-title {
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1.25;
  margin-bottom: 0;

  @include mq($from: md) {
    font-size: 2rem;
  }
}

.c-content-grid__item-text {
  margin-bottom: 0;
  margin-top: 1.5rem;
}

.c-content-grid__item-cta {
  margin-top: 1rem;
}

.c-content-grid__item-content {
  align-self: center;

  @include mq($from: md) {
    padding: 1.5rem 0;
  }

  .c-content-grid__item:nth-child(even) & {
    @include mq($from: md) {
      grid-column: 7 / -1;
    }

    @include mq($from: lg) {
      grid-column: 8 / -1;
    }
  }

  .c-content-grid__item:nth-child(odd) & {
    @include mq($from: md) {
      grid-column: 1 / span 6;
    }

    @include mq($from: lg) {
      grid-column: 2 / span 5;
    }
  }
}

.c-content-grid__item-image-container {
  .c-content-grid__item:nth-child(even) & {
    @include mq($from: md) {
      grid-column: 1 / span 6;
      justify-items: end;
    }
  }

  .c-content-grid__item:nth-child(odd) & {
    @include mq($from: md) {
      grid-column: 7 / span 6;
    }
  }
}

.c-content-grid__item-image {
  height: auto;

  @include mq($from: lg) {
    min-height: 100%;
    max-width: unset;
  }
}

.c-content-grid__item-small-image {
  margin-top: 1.5rem;
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */

.c-content-grid--bg-default {
  background-color: $color-blue-200;
}

.c-content-grid--bg-light {
  background-color: $color-blue-50;

  .c-content-grid__background-image {
    top: 15rem;
    transform: translateX(-20%);
    opacity: 0.4;

    @include mq($from: md) {
      transform: translateX(-40%);
      top: 10rem;
    }
  }
}

.c-content-grid--with-top-padding {
  padding-top: var(--vertical-spacing);

  section + & {
    padding-top: var(--vertical-spacing);
  }
}

.c-content-grid--flipped {
  .c-content-grid__item:nth-child(even) {
    .c-content-grid__item-content {
      @include mq($from: md) {
        grid-column: 1 / span 6;
      }

      @include mq($from: lg) {
        grid-column: 2 / span 5;
      }
    }

    .c-content-grid__item-image-container {
      @include mq($from: md) {
        grid-column: 7 / span 6;
        justify-items: start;
      }
    }
  }

  .c-content-grid__item:nth-child(odd) {
    .c-content-grid__item-content {
      @include mq($from: md) {
        grid-column: 7 / span 6;
      }

      @include mq($from: lg) {
        grid-column: 8 / span 5;
      }
    }

    .c-content-grid__item-image-container {
      @include mq($from: md) {
        grid-column: 1 / span 6;
        justify-items: end;
      }
    }
  }
}

.c-content-grid__item--quote {
  gap: 0;
  .c-content-grid__item-image-quote {
    margin: 0 auto;
    box-shadow: 0px 4px 40px 0px #0000001a;
    border-radius: 50%;
    overflow: hidden;
  }

  .c-content-grid__quote {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 1.33;
  }

  @include mq($from: md) {
    gap: 1rem;
    padding-top: calc(var(--vertical-spacing) / 2);
    padding-bottom: calc(var(--vertical-spacing) / 2);

    .c-content-grid__item-image-quote {
      grid-column: 1 / span 4;
      grid-row: span 2;
      height: fit-content;
      align-self: center;
    }

    .c-content-grid__quote {
      grid-column: 5 / span 8;
      margin: 0;
      align-self: end;
    }

    .c-content-grid__cite {
      grid-column: 5 / span 8;
      font-weight: 800;
    }
  }

  @include mq($from: lg) {
    .c-content-grid__item-image-quote {
      grid-column: 2 / span 3;
      margin: 0;
      width: fit-content;
      max-width: 100%;
    }

    .c-content-grid__item-image {
      width: 240px;
      max-width: 100%;
      aspect-ratio: 1;
    }

    .c-content-grid__quote {
      grid-column: 5 / span 7;
    }

    .c-content-grid__cite {
      grid-column: 5 / span 7;
    }
  }
}
