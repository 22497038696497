/**
 * Shared input
 * Shared base styles for input, eg. buttons, text inputs, etc.
 */

$shared-input-border-width: 1px !default;
$shared-input-padding-vertical: calc(
  (var(--target-small-height) - 1.5em) / 2 - #{$shared-input-border-width}
) !default;
$shared-input-padding-horizontal: 14px !default;

/**
  * 1. By default inherit all default font styles.
  * 2. Remove the border from the paddings for perfect control of the
  *    dimensions.
  */
@mixin shared-input {
  appearance: none;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding: $shared-input-padding-vertical $shared-input-padding-horizontal;
  border: $shared-input-border-width solid transparent;
  border-radius: $border-radius-base;
  transition-property: border color background-color box-shadow;
  transition-duration: $base-animation-duration;
  transition-timing-function: $base-animation-timing;
  font: inherit; /* [1] */

  &:focus {
    @include generic-focus;
  }
}
