/*  =========================================================================
    elements.typography
    ========================================================================= */

/*  Variables
    ========================================================================= */

/*  Functions/Mixins
    ========================================================================= */

/*  Module
    ========================================================================= */

html {
  font-family: var(--font-family-base, #{$font-family-base});
  color: $color-gray-900;

  .whitelabel-theme {
    color: $color-black;
  }
}

/**
 * Headings
 */
h1,
.h2,
h2,
.h3,
h3,
h4,
.h4,
h5,
h6,
dt {
  font-family: var(--font-family-base, #{$font-family-heading});
  font-weight: 800;
  margin-bottom: 0.75em; // compensates for 1.5 line-height
}

h1,
.h2,
h2,
.h3,
h3,
h4,
.h4,
h5,
h6,
p {
  max-width: 70ch;
}

h1 {
  line-height: $line-height-h1;
  font-size: $font-size-h1;
  font-size: clamp(#{$font-size-h3}, 5vw, #{$font-size-h1});
}

h2,
.h2 {
  font-size: $font-size-h2;
  line-height: $line-height-h2;
}

h3,
.h3 {
  font-size: $font-size-h3;
  line-height: $line-height-h3;
}

h4,
.h4 {
  font-size: $font-size-h4;
  line-height: $line-height-h4;
  font-weight: 700;
}
