/**
 * Full Banner section
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.c-full-banner {
  background-image: url('/img/full-banner-background.webp');
  padding: var(--vertical-spacing) 0;
  background-position: 50% 100%;
  background-repeat: no-repeat;
  min-height: 500px;
  display: grid;
  color: $color-blue-700;

  @include mq($from: lg) {
    min-height: 720px;
  }
}

/**
 * ======================================
 * Elements
 * ======================================
 */
.c-full-banner__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  align-self: center;
}

.c-full-banner__title {
  font-weight: 800;
  font-size: 2.5rem;
  line-height: 1.25;
  margin-bottom: 1rem;

  @include mq($from: md) {
    font-size: 3.875rem;
    margin-bottom: 2rem;
  }
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */

.c-full-banner--bg-default {
  background-color: $color-white;
}

.c-full-banner--bg-light {
  background-color: $color-blue-50;
}
