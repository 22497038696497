/**
 * Spacing utility
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.u-negative-margin-bottom-tiny {
  margin-bottom: -$supple-space-tiny;
}

.u-negative-margin-bottom-small {
  margin-bottom: -$supple-space-small;
}

.u-negative-margin-bottom-base {
  margin-bottom: -$supple-space-base;
}

.u-negative-margin-bottom-large {
  margin-bottom: -$supple-space-large;
}

/**
 * ======================================
 * Elements
 * ======================================
 */

/**
 * ======================================
 * Modifiers
 * ======================================
 */
.u-margin-bottom-small--not-last-child {
  margin-bottom: $supple-space-small;

  &:last-child {
    margin-bottom: 0;
  }
}
