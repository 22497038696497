/**
 * Upload CV section
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.c-upload-cv {
  background-color: $color-blue-50;
  padding: calc(var(--vertical-spacing) / 2) 0;
  position: relative;

  @include mq($from: md) {
    padding: var(--vertical-spacing) 0;
  }
}

/**
 * ======================================
 * Elements
 * ======================================
 */
.c-upload-cv__container {
  display: grid;
  gap: 2rem;
  position: relative;

  @include mq($from: md) {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    gap: 1rem;
  }
}

.c-upload-cv__visual-container {
  display: flex;
  justify-content: center;

  @include mq($from: md) {
    grid-column: 1 / span 6;
  }
}

.c-upload-cv__visual {
  --visual-offset: 3rem;

  width: 100%;
  height: auto;
  max-width: 400px;
  margin: 0 auto;

  @include mq($from: md) {
    position: absolute;
    height: calc(100% + (var(--vertical-spacing) + var(--visual-offset)) * 2);
    width: auto;
    top: calc((var(--visual-offset) + var(--vertical-spacing)) * -1);
    max-width: 100%;
    z-index: 1;
  }
}

.c-upload-cv__content {
  align-self: center;

  @include mq($from: md) {
    grid-column: 8 / -1;
  }
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */
