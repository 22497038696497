/**
 * Block: select
 */
.c-select {
  position: relative;
  display: inline-block;
  width: 100%;
}

/**
 * Element: input
 * 1. Relies on the `shared-input` mixin in `01-tools/mixins/_shared-input.scss`
 * 2. Accommodate space for the icon
 * 3. Set explicit height because firefox does not respect the line-height
 */
.c-select__input {
  @include shared-input; /* [1] */
  width: 100%;
  @include supple-rem(padding-right, 36px);
  border-color: $color-gray-200;

  &:hover,
  &:focus {
    border-color: $color-blue-500;
  }

  /**
   * State: valid/invalid
   * 1. Shit needs to be immutable
   */
  &.is-valid {
    border-color: $color-green-500 !important; /* [1] */
  }

  &.is-invalid {
    border-color: $color-red-500 !important; /* [1] */
  }

  /**
   * State: disabled
   * 1. Shit needs to be immutable, disabled is disabled
   */
  &:disabled,
  &.is-disabled {
    border-color: $color-gray-500;
    color: $color-gray-500 !important; /* [1] */
    background-color: $color-gray-100 !important; /* [1] */
    cursor: not-allowed;
  }

  @media (prefers-color-scheme: light) {
    background: $color-white;
  }
}

/**
 * Element: icon
 */
.c-select__icon svg {
  position: absolute;
  top: 50%;
  @include supple-rem(right, $supple-space-small);
  @include supple-rem(width, 10px);
  @include supple-rem(height, 10px);
  transform: translateY(-50%);
  pointer-events: none;
  user-select: none;
  color: $color-blue-500;
}

/**
 * Modifier: compact
 */
.c-select--compact {
  .c-select__input {
    padding-right: $supple-space-base;
  }

  .c-select__icon {
    @include supple-rem(right, $supple-space-tiny);
  }
}

.c-select.c-autocomplete--branch-filter {
  .c-select__input {
    background-color: transparent;
    border: none;
    border-bottom: $border-base;
    border-bottom-color: $color-blue-200;
    border-radius: 0;
    padding-left: $supple-space-tiny;
    padding-bottom: $supple-space-tiny;
    color: $color-white;

    option {
      background: $color-white;
      color: $color-gray-900;

      @media (prefers-color-scheme: dark) {
        background: $color-gray-900;
        color: $color-white;
      }
    }

    &:disabled,
    &.is-disabled {
      background-color: transparent;
      border: none;
      border-bottom: $border-base;
      border-bottom-style: dotted;
      font-style: italic;
    }

    &:hover,
    &:focus {
      border-bottom-color: $color-white;
    }
  }

  .c-select__icon svg {
    color: $color-white;
  }
}
