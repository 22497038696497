/**
 * Subscriptions
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.c-subscriptions {
  display: grid;
  flex-wrap: wrap;
  justify-content: center;
  gap: $supple-space-large;

  @include mq($from: md) {
    grid-template-columns: repeat(2, minmax(0, 400px));
  }

  @include mq($from: lg) {
    gap: $supple-space-huge;
  }
  @include mq($from: xl) {
    grid-template-columns: repeat(4, minmax(0, 400px));
    gap: $supple-space-small;
  }
}

/**
 * ======================================
 * Elements
 * ======================================
 */

.c-subsriptions__list-item {
  height: 100%;
}

/**
* ======================================
 * Modifiers
 * ======================================
 */
