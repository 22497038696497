/**
 * ======================================
 * Block
 * ======================================
 */

.c-icon-heading {
  font-weight: 800;
  font-size: 1.5rem;
  display: grid;
  grid-template: auto / auto 1fr;
  gap: 0 1ch;
  margin-bottom: 1em;
}

/**
 * ======================================
 * Elements
 * ======================================
 */

.c-icon-heading__icon-container {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-blue-500;
  color: $color-white;
}

.c-icon-heading__icon {
  max-width: 1em;
  max-height: 1em;
  flex: 0 0 auto;
}

.c-icon-heading__heading {
  font-size: 1em;
  line-height: 1;
  align-self: center;
  margin: 0;
}
