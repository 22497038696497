/**
 * Autowhatever
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.c-autowhatever {
  margin-bottom: $supple-space-tiny;

  &:focus-within {
    .c-autowhatever__clear-button,
    .c-autowhatever__icon,
    .c-autowhatever__hint {
      opacity: 1;
    }
  }

  @include mq($from: md) {
    margin-bottom: 0;
  }
}

/**
 * ======================================
 * Elements
 * ======================================
 */
.react-autowhatever__container {
  position: relative;
}

.c-autowhatever__input {
  @include shared-input; /* [1] */
  width: 100%;
  box-shadow: none;
  min-height: var(--target-base-height);
  border: 1px solid $color-gray-500;
  border-radius: $border-radius-base;
  // Padding to the edge of the clear button
  padding-right: 68px;

  &:hover,
  &:focus {
    border-color: $color-blue-500;
    box-shadow: none;
    outline: none;
  }

  &::placeholder {
    color: $color-gray-300;
  }
}

.c-autowhatever__clear-button,
.c-autowhatever__icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.c-autowhatever__clear-button {
  display: flex;
  align-items: center;
  justify-content: center;
  // Keep the same color as the Google Place autocomplete
  color: hsl(0, 0%, 80%);
  right: $supple-space-base;

  .c-button__icon {
    width: 20px;
    height: 20px;
  }
  .c-button__inner {
    margin-left: 0;
  }
  .c-button__inner > * {
    padding-left: 0;
  }

  &:hover,
  &:focus {
    color: hsl(0, 0%, 30%);
  }

  &:focus {
    outline: none;
  }

  .c-autowhatever--is-focused & {
    color: hsl(0, 0%, 40%);
  }
}

.c-autowhatever__icon {
  opacity: 0;
  color: $color-blue-500;
  right: $supple-space-tiny;
  transition: opacity $base-animation-duration $base-animation-timing;

  .c-autowhatever--is-focused & {
    opacity: 1;
  }
}

.c-autowhatever__loader {
  // Position the loader over the clear button
  right: 46px;
}

.react-autowhatever__items-container {
  display: none;
}

.c-autowhatever__list-container {
  position: relative;
}

.react-autowhatever__items-list {
  margin: 0;
  padding: 5px 0;
  list-style-type: none;
}

.react-autowhatever__item {
  cursor: pointer;
  color: $color-black;
  padding: 10px 14px;
}

.c-autowhatever__hint {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 100%;
  color: rgba($color-white, 0.8);
  font-size: 0.875rem;
  background: $color-blue-500;
  border-radius: 3px;
  padding: 0px 7px 2px;
  transition: opacity $base-animation-duration $base-animation-timing;

  .c-autowhatever--is-focused & {
    opacity: 1;
  }
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */
.react-autowhatever__items-container--open {
  display: block;
  position: absolute;
  top: calc(100% + #{$supple-space-tiny});
  width: 100%;
  border: 1px solid $color-gray-500;
  border-radius: $border-radius-base;
  background-color: #fff;
  font-size: 1rem;
  line-height: 1.25;
  z-index: 2;
  box-shadow: $box-shadow-base;
  max-height: 260px;
  overflow-y: auto;
}

.react-autowhatever__item--highlighted {
  background-color: rgba($color-blue-500, 0.1);
}
